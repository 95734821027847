/* GLOBALS CSS CLASS */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

ul,
ol,
li,
a {
  text-decoration: none;
  list-style: none;
}

/* Media Queries */

// @media all and (min-width: 1536px) {

// }
// @media all and (max-width: 1280px) {
// }
// @media all and (max-width: 1024px) {

// }
// @media all and (max-width: 768px) {

// }
// @media all and (max-width: 640px) {

// }
